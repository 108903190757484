<template>
  <div class="warehouseType">
    <div class="pvhFactory">
        <div class="">
            <div class="layTop d-flex">
                <div class="Title" style="margin-bottom: 10px;">{{ $t('Warehouse Type') }}</div>

            </div>
            <div style="padding-top: 10px; text-align: left; align-self: flex-end;">
                <el-row class="topFromSearch">
                    <el-col class="formDiv" :xs="24" :sm="21" :md="21" :lg="21" :xl="21" style="align-self: flex-end; ">
                        <el-input style="width:550px;" v-model="searchVal" :placeholder="$t('PLEASE ENTER THE KEYWORDS TO BE SEARCHED')"></el-input>
                        <span id="statusSpan" style="display:none;">{{$t('STATE')}}:</span>
                        <el-select v-model="statusVal" @focus="focusDiv($event)" @blur="blurDiv($event)" collapse-tags placeholder="" style="width:100%;display:none;">
                            <el-option v-for="item in this.statusData"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                        <el-button :title="$t('Search')" icon="iconfont sousuo" size="mini" type="primary" @click="search"> {{$t('Search')}}</el-button>
                        <el-button type="primary" icon="iconfont tianjia" size="mini" :title="$t('New')" plain @click="addBtn"> {{$t('New')}}</el-button>
                    </el-col>
                    <el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3" style="padding-top: 20px; text-align: right; align-self: flex-end;">
                        
                    </el-col>
                </el-row>
            </div>
            <div style="margin-top: 10px;">
                <avue-crud class="pvhTable"
                           v-loading="loading"
                           v-model="obj"
                           :option="tableOpt"
                           :data="tableData"
                           :row-style="rowStyle"
                           :page.sync="page"
                           @current-change="currentChange">

                    <template slot="menuRight" style="width: calc(100% - 80px);">
                        <el-row :gutter="20" class="topFromSearch">

                            <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top: 10px;">
                                <el-button type="primary" size="small" icon="el-icon-plus" style="margin-left:10px;">
                                    {{ $t('ADD') }}
                                </el-button>
                            </el-col>
                        </el-row>
                    </template>
                    <template slot-scope="{row,index,size,type}" slot="menu">
                        <el-button type="text" size="small" @click="editBtn(row)">{{ $t('Edit') }}</el-button>
                        <el-button type="text" size="small" @click="delBtn(row)">{{ $t('Delete') }}</el-button>
                    </template>

                </avue-crud>
            </div>
        </div>
    </div>

    <el-dialog
        :title="$t('Warehouse Type')"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="handleClose">
      <div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
              <el-form-item :label="$t('Id')" prop="id" style="display:none">
                  <el-input v-model="ruleForm.Id" ></el-input>
              </el-form-item>
              <el-form-item :label="$t('Code')" prop="code">
                  <el-input v-model="ruleForm.code" :disabled="ruleForm.id"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Name')" prop="name">
                  <el-input v-model="ruleForm.name"></el-input>
              </el-form-item>
              <el-form-item :label="$t('REMARKS')" prop="code">
                  <el-input v-model="ruleForm.description"></el-input>
              </el-form-item>
              <el-form-item>
                  <el-button type="primary" @click="submitForm('ruleForm')">OK</el-button>
              </el-form-item>
          </el-form>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {
        GetWarehouseTypes,
        GetWarehouseType,
        PostWarehouseType,
        PutWarehouseType,
        DeleteWarehouseType
} from '@/api/warehouse'

export default {
  name: "warehouseType",
  data() {
    return {
      loading: false,
      option: [],
      ruleForm: {
        id: false,
        code: '',
        name: '',
        description: ''
      },
      rules: {
        code: [
          {required: true, message: this.$t('Please enter'), trigger: 'blur'},
        ],
        name: [
          {required: true, message: this.$t('Please enter'), trigger: 'blur'},
        ],
      },
      dialogVisible: false,
      YesNoData: [
        {
          label: this.$t('Disable'),
          value: 1
        }, {
          label: this.$t('Available'),
          value: 0
        }

      ],
      page: {
        currentPage: 1,
        total: 0,
        layout: "total,pager,prev, next",
        background: true,
        pageSize: 10
      },
      tableData: [],
      obj: {},
      searchVal: null,
      statusVal:null,
      statusData: [
        {
            label: this.$t('NORMAL'),
            value: 0
        },
        {
            label: this.$t('DELETE'),
            value: 1
        }
      ],
    }
  },
  created() {
    this.list();
  },
  methods: {
    submitForm(formName) {
      var ruleForm = this.ruleForm;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          if (ruleForm.id) {
              PutWarehouseType({
              id: ruleForm.Id,
              name: ruleForm.name,
              description: ruleForm.description              
            }).then((response) => {
              if (response.data.statusCode == 200 && !response.data.isError) {
                this.$message({message: this.$t('OperationSuccess'), type: "success"});
                this.page.total = 0;
                this.list();
              } else {
                this.$message({message: this.$t('OperationFailed'), type: "warning"});
              }
              this.resetForm('ruleForm')
            })
          } else {
              PostWarehouseType({
                code: ruleForm.code,
                name: ruleForm.name,
                description: ruleForm.description,
                docStatus: 0
              }).then((response) => {
              if (response.data.statusCode == 200 && !response.data.isError) {
                this.$message({message: this.$t('OperationSuccess'), type: "success"});
                this.page.total = 0;
                this.list();
              } else {
                this.$message({message: this.$t('OperationFailed'), type: "warning"});
              }
              this.resetForm('ruleForm')
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.ruleForm = {
        id: '',
        code: '',
        name: '',
        description: ''
      }
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
    },
    delBtn(row) {
        DeleteWarehouseType(row.id).then((response) => {
            if (response.data.statusCode == 200 && !response.data.isError) {
                this.$message({ message: this.$t('OperationSuccess'), type: "success" });
                this.page.total = 0;
                this.list();
            } else {
                this.$message({ message: this.$t('OperationFailed'), type: "warning" });
            }
      })
    },
    editBtn(row) {
      var ruleForm = this.ruleForm;
      this.dialogVisible = true;
      this.ruleForm.id = true;
      ruleForm.code = row.code;
      ruleForm.name = row.name;
      ruleForm.description = row.description;
      ruleForm.Id = row.id;
      },
      search() {
          this.list();
      },
    list() {
        this.loading = true;
        GetWarehouseTypes(this.searchVal, this.statusVal, this.page.currentPage, this.page.pageSize).then((response) => {
        this.loading = false;
        this.tableData = response.data.warehouseTypes;
        this.page.total = response.data.totalCount;
        this.page.pageSize = response.data.pageSize;
      })
    },
    addBtn() {
      this.dialogVisible = true;
      this.ruleForm.id = false;
    },
    rowStyle({rowIndex}) {
      if (rowIndex % 2 === 0) {
        return {
          backgroundColor: '#F8F8F8'
        }
      }
    },
    currentChange(val) {
      this.page.currentPage = val
      this.list()
      // this.getOperateLogList();
    },
    handleClose() {
      this.resetForm('ruleForm');
      this.dialogVisible = false;
    }
  },
  computed: {
    tableOpt() {
      return {
        page: true,
        addBtn: true,
        refreshBtn: false,
        emptyText: this.$t('No Data'),
        menu: true,
        menuTitle: this.$t('OPERATION'),
        menuWidth: 300,
        editBtn: false,
        editBtnText: this.$t('Edit'),
        editTitle: this.$t('Edit'),
        addTitle: this.$t('ADD'),
        delBtn: false,
        align: 'center',
        header: false,
        simplePage: false,
        labelWidth: "33%",
        expand: false,
        rowKey: 'Id',
        expandRowKeys: [],
        ListByCorpData: [],
        column: [
          {
            label: this.$t('Name'),
            prop: 'name',
          },
          {
            label: this.$t('Code'),
            prop: 'code',
          },
          {
              label: this.$t('DESCRIPTION'),
            prop: 'description',
          },
          {
            label: this.$t('STATE'),
            prop: 'docStatus',
            type: 'select',
            dicData: this.YesNoData,
          }
        ]
      }
    },
  }
}
</script>

<style scoped lang="scss">
.warehouseType {
  padding: 15px;

  .pvhFactory {
    padding: 15px;
    border-radius: 8px;
    background: #FFFFFF;
  }
}

.layTop {
  justify-content: space-between;
}

.d-flex {
  display: flex;
}
</style>
